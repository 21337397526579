import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Picture } from '@domain/models/picture.model';
import { Router } from '@angular/router';
import { Project } from '@domain/models/project.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-picture-item',
  templateUrl: './picture-item.component.html',
  styleUrls: ['./picture-item.component.scss']
})
export class InventoryPictureItemComponent {
  get picture(): Picture {
    return this._picture;
  }
  @Input()
  set picture(value: Picture) {
    this._picture = value;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(value.data);
  }
  @Input() public project: Project;
  @Input() public disabled: boolean;

  @Output() public print: EventEmitter<string> = new EventEmitter<string>();
  @Output() public delete: EventEmitter<string> = new EventEmitter<string>();

  public url: SafeResourceUrl;
  private _picture: Picture;

  constructor(private router: Router,
              private sanitizer: DomSanitizer) {
  }

  /**
   * On Print click
   *
   * @param event: any
   * @param pictureId: string
   *
   * @returns void
   */
  public onPrintClick(event, pictureId: string): void {
    event.stopPropagation();
    this.print.emit(pictureId);
  }

  /**
   * On Delete click
   *
   * @param event: any
   * @param pictureId: string
   *
   * @returns void
   */
  public onDeleteClick(event, pictureId: string): void {
    if (!this.disabled) {
      event.stopPropagation();
      this.delete.emit(pictureId);
    }
  }

  /**
   * On Save click
   *
   * @param event: any
   *
   * @returns void
   */
  public onSaveClick(event): void {
    if (!this.disabled) {
      event.stopPropagation();
    }
  }

  /**
   * Route to picture detail
   *
   * @param event
   * @param pictureId: string
   * @returns void
   */
  public onPictureClick(event: any, pictureId: string): void {
    event.preventDefault();
    this.router.navigateByUrl(`/admin/project/${this.project.id}/pictures(popup:admin/project/pictures/${pictureId})`);
  }
}
