import { DomainModel } from '@domain/domain.model';
import { Task } from '@domain/models/task.model';

import * as uuid from 'uuid/v4';

export class QuotationTask extends DomainModel {
  // Configuration
  public entity = 'quotation-task';
  public table = 'quotation_tasks';
  public schema = 'id, remote_id, quotation_id, task_id';
  public sync = true;
  public id: string;

  // Fields
  public quotation_id: string;
  public task_id: number;
  public time: Date;

  public task: Task;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    // Set relations
    this.task = await Task.query.get(this.task_id);
  }

  public getData(): any {
    return {
      id: this.id,
      quotation_id: this.quotation_id,
      task_id: this.task_id,
      time: this.formatDate(this.time)
    };
  }
}
