import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { ProjectModule } from '@features/project/project.module';
import { QuotationSignComponent } from '@features/quotationSign/quotation-sign.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CalendarModule, CheckboxModule } from '@root/node_modules/primeng/primeng';
import { SignaturePadModule } from 'angular2-signaturepad';

@NgModule({
  declarations: [
    QuotationSignComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    CheckboxModule,
    CalendarModule,
    SignaturePadModule,
    ProjectModule,
    CurrencyMaskModule
  ],
  exports: []
})
export class QuotationSignModule {
}
