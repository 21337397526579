import { DomainModel } from '@domain/domain.model';
import { Material } from '@domain/models/material.model';

import * as uuid from 'uuid/v4';

export class QuotationMaterial extends DomainModel {
  // Configuration
  public entity = 'quotation-material';
  public table = 'quotation_materials';
  public schema = 'id, remote_id, quotation_id, material_id';
  public sync = true;
  public id: string;

  // Fields
  public quotation_id: string;
  public material_id: number;
  public amount: number;

  public material: Material;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    // Set relations
    this.material = await Material.query.get(this.material_id);
  }

  public getData(): any {
    return {
      id: this.id,
      quotation_id: this.quotation_id,
      material_id: this.material_id,
      amount: this.amount
    };
  }
}
